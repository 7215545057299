@media (min-width: 1300px){
  .container{max-width: 1200px;}
}
.col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
  float: left;
}
.clearfix{
  clear:both;
}
* {
  margin: 0px;
  padding: 0px;
  border: none;
  outline: none;
}
a{
  text-decoration:none;
  transition:all 500ms ease;
  -moz-transition:all 500ms ease;
  -webkit-transition:all 500ms ease;
  -ms-transition:all 500ms ease;
  -o-transition:all 500ms ease;
}
a:hover{
  text-decoration:none;
  outline:none;
}
input,button,select,textarea{
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  font-size: 16px;
}
ul,li{
  list-style:none;
  padding:0px;
  margin:0px; 
}
input{
  transition: all 500ms ease;
}
button:focus,
input:focus,
textarea:focus{
  outline: none;
  box-shadow: none;
  transition: all 500ms ease;
}
p{
  position: relative;
  font-size: 16px;
  line-height: 30px;
  color: #444444;
  font-family: 'Lato', sans-serif;
  margin-bottom: 0px;
  font-style: normal;
  transition: all 500ms ease;
}
h1,h2,h3,h4,h5,h6{
  position:relative;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  color: #161616;
  margin: 0px;
  transition: all 500ms ease;
}
/* --------------------- Menu Section ------------------------------ */
.header-middle{
  position: relative;
  left: 0px;
  top: 0px;
  z-index: 999;
  width: 100%;
}
.outer-box {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
}
.header-middle img {
max-width: 300px;
max-height: 77px;
}
ul.main-menu>li.menu-item>a {
margin-left: 10px;
margin-right: 10px;
padding-top: 25px;
padding-bottom: 25px;
font-size: 17px;
text-transform: capitalize;
font-weight: 500;
color: #312e2e;
letter-spacing: 1px;
}
.menu-item {
  list-style: none;
}
.menu-item a{
  color: #0c0c0c;
  font-size: 17px;
}
.menu-item a:hover{
  color: #f35202;
}
.is-active {
  color: #f35202!important;
}
ul.main-menu>li.menu-item .aaa2{
  color: #0082ff;
  font-weight: 700;
}
@media (min-width: 992px){
 ul.main-menu {
   display: flex;
   position: relative;
   list-style: none;
   margin: 23px 0 0 0;
   padding: 0;
   float: right;
  }
  ul.main-menu>li.menu-item:first-child { padding-left: 0;}
  ul.main-menu>li.menu-item>a:last-child {margin-right: 0; }
  ul.main-menu>li.menu-item>a {  margin-left: 30px; margin-right: 15px;}
  ul.main-menu>li .sub-menu { padding: 30px;  }
  ul.main-menu>li .sub-menu {
       width: 227px;
       position: absolute;
       top: 100%;
       left: 0;
       background: #f7f7f7;
       border: 2px solid #eee;
       margin-top: 7px;
       opacity: 0;
       pointer-events: none;
     }
     ul.main-menu>li .sub-menu li {
         margin-bottom: 15px;
         color: #333;
         text-transform: capitalize;
         font-weight: 400;
         font-size: 14px;
         margin-bottom: 10px;
     }
     ul.main-menu .has-children:hover>.sub-menu { pointer-events: visible;  opacity: 1; }
     ul.main-menu .has-children:hover {z-index: 200;}
ul.main-menu .has-children {position: relative;}
}  
@media (max-width: 991px){ 
.menuq1{display:none;}
.menuq2{ display:block!important;}
.res5c {
  display: table!important;
  float: none;
  background-color: #34465e;
  color: #fff;
  padding: 7px;
  border-radius: 5px;
  margin: 10px auto;
}
}

/* -------------------------------- banner ----------------------------------------- */
.banner {
  position: relative;
  padding: 77px 0px 35px 0px;
  height: 100vh;
  overflow: hidden;
}
.banner p {
  font-size: 18px;
  line-height: 30px;
  margin-bottom: 17px;
  margin-top: 17px;
}
.bg-layer{
    position: absolute;left: 0px;top: 0px; width: 100%;
    height: 100vh;
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;
    z-index: -7;
    background-image: url(./Img/delete/slide-1.jpg);
}
.bannsss{
  margin-top: 20%;
}
.bannsss span{
    color: #7d2ced;
    letter-spacing: 2px;
    font-size: 20px;
    text-shadow: 0px 1px 1px #929292;
    margin-bottom: 17px;
    display: block;
}
.bannsss h1 {
  font-size: 40px;
  letter-spacing: 1px;
  margin-bottom: 20px;
}
.bannsss h1 b{
  color: #0082ff;
}
.banner-section .content-box .theme-btn.style-one {
  margin-right: 20px;
}
.theme-btn.style-one {
  background: linear-gradient(90deg, var(--c1, #842af1), var(--c2, #033da4 ) 50%, var(--c1, #842af1)) var(--x, 0)/ 200%;
}

.theme-btn {
  margin-right: 27px;
  margin-top: 27px;
  position: relative;
  display: inline-block;
  overflow: hidden;
  font-size: 16px;
  line-height: 30px;
  font-weight: 700;
  font-family: 'Poppins', sans-serif;
  color: #fff !important;
  padding: 14px 32px;
  text-align: center;
  cursor: pointer;
  border-radius: 8px;
  z-index: 1;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  transition: all 500ms ease;
}
.theme-btn.style-one:before, .theme-btn.style-two:before, .theme-btn.style-three:before {
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  right: 0px;
  z-index: -1;
  clip-path: polygon(0% 103%, 400% 0, 100% 100%, 0% 100%, 0 0);
  transition: all 500ms ease;
}
.theme-btn.style-one:before {
  background: linear-gradient(90deg, var(--c1, #ffc0cb), var(--c2, #ff165e ) 50%, var(--c1, #ffc0cb)) var(--x, 0)/ 200%;
}
.theme-btn.style-one:after, .theme-btn.style-two:after, .theme-btn.style-three:after {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
  -webkit-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.theme-btn.style-one:after {
  background: linear-gradient(90deg, var(--c1, #ffc0cb), var(--c2, #ff165e ) 50%, var(--c1, #ffc0cb)) var(--x, 0)/ 200%;
}
.theme-btn:hover:after {
  opacity: 1;
}
.theme-btn.style-one:hover:after, .theme-btn.style-two:hover:after, .theme-btn.style-three:hover:after {
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
  -webkit-transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
  transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
}
.theme-btn.style-two {
  background: linear-gradient(90deg, var(--c1, #d201b6), var(--c2, #fe1660 ) 50%, var(--c1, #d201b6)) var(--x, 0)/ 200%;
}
.theme-btn.style-two:before {
  background: linear-gradient(90deg, var(--c1, #FFA500), var(--c2, #ffd200 ) 50%, var(--c1, #FFA500)) var(--x, 0)/ 200%;
}
.theme-btn.style-two:after {
  background: linear-gradient(90deg, var(--c1, #FFA500), var(--c2, #ffd200 ) 50%, var(--c1, #FFA500)) var(--x, 0)/ 200%;
}
.svgfa {
  overflow: visible;
  margin-right: 4px;
}
.bg-color-1s {
  background: #eef2fe;
  z-index: 0;
}
/* ------------------------------------------------------------------------- */
/** ------------ pricing-style-two ------------------**/
.pricing-section.style-two{
  position: relative;
  padding: 67px 0 67px 0px;
}
.pricing-section.style-two .tab-btn-box .tab-btns:before{
  border: 2px solid #e7ecfd;
}
.pricing-block-two .pricing-table .choice-box{
  position: absolute;
  width: 50%;
  left: -130px;
  top: 0px;
  text-align: center;
  padding: 3px 30px;
  transform: rotate(-45deg);
  opacity: 0;
  transition: all 900ms ease;
}
.pricing-block-two .pricing-table .choice-box h6{
  color: #fff;
  font-size: 16px;
  line-height: 24px;
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
}
.pricing-block-two .pricing-table .choice-box {
  background: #fc668b;
}
.pricing-block-two.active_block .pricing-table .choice-box{
  opacity: 1;
  left: -44px;
  top: 25px;
}
.pricing-block-two .pricing-table{
  position: relative;
  display: block;
  overflow: hidden;
  border-radius: 15px;
  background: #fff;
  padding: 66px 30px 50px 30px;
  box-shadow: 0 0px 50px #b8daff;
  transition: all 500ms ease;
}
.pricing-block-two .pricing-table:before{
  position: absolute;
  content: '';
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  opacity: 0;
  -webkit-transform-origin: top;
  -ms-transform-origin: top;
  transform-origin: top;
  -webkit-transform: scale(.9) translateY(20px);
  -ms-transform: scale(.9) translateY(20px);
  transform: scale(.9) translateY(20px);
  background: -webkit-linear-gradient(0deg, #00bdff, #0082ff 50%);
  transition: all 500ms ease;
}
.pricing-block-two.active_block .pricing-table:before{
  opacity: 1;
  -webkit-transform: scale(1) translateY(0px);
  -ms-transform: scale(1) translateY(0px);
  transform: scale(1) translateY(0px);
  -webkit-transition: 1s;
  -o-transition: 1s;
  transition: 1s;
}
.pricing-block-two .pricing-table .table-header h4{
  position: relative;
  display: block;
  margin-bottom: 18px;
}
.pricing-block-two .pricing-table .table-header h2{
  position: relative;
  display: inline-block;
  font-size: 72px;
  line-height: 75px;
  font-weight: 600;
  transition: all 500ms ease;
}
.pricing-block-two .pricing-table .table-header h2:before{
  position: absolute;
  content: '$';
  font-size: 25px;
  font-family: 'Poppins', sans-serif;
  color: #161616;
  line-height: 30px;
  font-weight: 600;
  left: -20px;
  top: 6px;
  transition: all 500ms ease;
}
.pricing-block-two.active_block .pricing-table .table-header h2,
.pricing-block-two.active_block .pricing-table .table-header h2:before{
  color: #fff;
}
.pricing-block-two .pricing-table .table-header{
  position: relative;
  text-align: center;
}
.pricing-block-two .pricing-table .table-content{
  position: relative;
  min-height: 167px;
  padding: 32px 0px 47px 45px;
}
.pricing-block-two .pricing-table .table-content li{
  position: relative;
  display: block;
  font-size: 15px;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
  color: #161616;
  margin-bottom: 25px;
  padding-left: 32px;
  transition: all 500ms ease;
}
.pricing-block-two.active_block .pricing-table .table-content li{
  color: #fff;
}
.pricing-block-two .pricing-table .table-content li:last-child{
  margin-bottom: 0px;
}
.pricing-block-two .pricing-table .table-content li span{
  position: absolute;
  left: 0px;
  top: 5px;
  width: 22px;
  height: 22px;
  line-height: 22px;
  text-align: center;
  font-size: 14px;
  color: #fff;
  border-radius: 50%;
  box-shadow: 0 10px 20px rgba(64, 222, 3, 0.4);
}
.sec-title h3 {
  position: relative;
  font-size: 36px;
  line-height: 47px;
  color: #161616;
  font-weight: 700;
  margin-bottom: 47px;
}
.pricing-block-two .pricing-table .table-content li span{
  background: -webkit-linear-gradient(0deg, #0cf40c, #06c106 50%);
}
.pricing-block-two .pricing-table .table-footer{
  position: relative;
  text-align: center;
}
.pricing-block-two .pricing-table .table-footer a{
  position: relative;
  display: inline-block;
  overflow: hidden;
  font-size: 14px;
  line-height: 30px;
  font-weight: 700;
  font-family: 'Poppins', sans-serif;
  color: #fff;
  padding: 11px 40px;
  border-radius: 30px;
  text-align: center;
  z-index: 1;
  box-shadow: 0 5px 20px #fbbfdc;
}
.pricing-block:nth-child(2) .pricing-block-two .pricing-table .table-footer a{
  box-shadow: 0 5px 20px #b6eefe;
}
.pricing-block:last-child .pricing-block-two .pricing-table .table-footer a{
  box-shadow: 0 5px 20px #fad3b4;
}
.pricing-section.style-two .pricing-block{
  padding: 0px 0px;
}
.pricing-block-two.active_block .pricing-table .table-footer a{
  background: #fff !important;
  color: #161616;
  box-shadow: 0 5px 20px rgba(0,0,0,0.3) !important;
}
.pricing-section.style-two .inner-content{
  position: relative;
  margin: 0px 15px;
}
.pricing-section.style-two .pricing-block-two .pricing-table .pattern-layer{
  position: absolute;
  left: -15px;
  bottom: -15px;
  width: 355px;
  height: 320px;
  background-repeat: no-repeat;
  background-image: url(./Img/pattern-12.png);
}
.pricing-block-two .pricing-table .table-footer a {
  background: linear-gradient(90deg, var(--c1, #fe7177), var(--c2, #fa5da2 ) 50%, var(--c1, #fe7177)) var(--x, 0)/ 200%;
}
.pricing-block:last-child .pricing-block-two .pricing-table .table-footer a {
  background: linear-gradient(90deg, var(--c1, #fdc032), var(--c2, #f6722a ) 50%, var(--c1, #fdc032)) var(--x, 0)/ 200%;
}
.pricing-section.style-two .image-layer .image{
  position: absolute;
}
.pricing-section.style-two .image-layer .image-1{
  left: 0px;
  bottom: 150px;
}
.pricing-section.style-two .image-layer .image-2{
  right: 0px;
  bottom: 250px;
}
/* ------------------------ Gold ------------------------------------------------- */
.hhhsss{
    height: 127px;
    overflow: hidden;
}

.gold-section .news-block-one .post-info {
  margin-top: -37px;
}

/** sec-title **/
.sec-title{
  position: relative;
  display: block;
}
.sec-title.centred{
  text-align: center;
}
.sec-title .title{
  position: relative;
  display: inline-block;
  font-size: 14px;
  line-height: 24px;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  background: #fff;
  padding: 5px 15px 6px 42px;
  margin-bottom: 18px;
  border-radius: 30px;
  box-shadow: 0 0px 10px rgba(0, 0, 0, 0.1);
}
.sec-title .title-two{
  position: relative;
  margin-bottom: 8px;
}
.sec-title .title-two p{
  position: relative;
  font-size: 18px;
  line-height: 30px;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  color: #444444;
}
.sec-title .title-two span{
  position: relative;
  top: 3px;
  font-size: 24px;
  color: transparent !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
}
.gold-section {
  position: relative;
  padding: 67px 0 0 0px;
}
.gold-section .title .gold2{
  position: absolute;
  left: 0px;
  top: 0px;
  width: 35px;
  height: 35px;
  line-height: 35px;
  color: #fff;
  text-align: center;
  border-radius: 50%;
}
.gold-section .title .gold2 {
  background: linear-gradient(90deg, var(--c1, #58f105), var(--c2, #20bf0e ) 50%, var(--c1, #58f105)) var(--x, 0)/ 200%;
}
.sec-title h3{
  position: relative;
  font-size: 36px;
  line-height: 47px;
  color: #161616;
  font-weight: 700;
}
.sec-title h3 span{
  font-weight: 400;
}
.sec-title h2{
  position: relative;
  font-size: 48px;
  line-height: 58px;
  color: #161616;
  font-weight: 700;
}
.sec-title.light .title,
.sec-title.light .title-two p,
.sec-title.light h2,
.sec-title.light h3{
  color: #fff;
}
/** news-section **/
.news-section{
  position: relative;
  background: #fff;
  padding: 195px 0px 40px 0px;
}
.news-block-one .inner-box{
  position: relative;
  display: block;
  overflow: hidden;
  border: 1px solid #e4ecfb;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 10px 20px #dfe2eb;
  transition: all 500ms ease;
}
.news-block-one .inner-box:hover{
  box-shadow: 0 10px 30px #c2c6d9;
}
.news-block-one .inner-box .image-box{
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  min-height: 233px;
  max-height: 233px;
  background-color: #f7f7f7;
}
.news-block-one .overlay-box {
  background: -webkit-linear-gradient(90deg, rgba(132, 42, 241, 0.8), rgba(3, 61, 164, 0.8) 50%);
}
.news-block-one .inner-box .image-box img {
  width: 100%;
  border-radius: 10px;
  background-size: cover;
  object-fit: cover;
  -o-object-position: center right;
  object-position: center center;
  margin: 0 auto;
  display: block;
  height: 233px;
}
.news-block-one{
  position: relative;
  margin: 30px 0px;
  margin-bottom: 40px;
}
.news-block-one .overlay-box {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  text-align: center;
  content: "";
  -webkit-transition: -webkit-transform 0.4s ease;
  transition: -webkit-transform 0.4s ease;
  transition: transform 0.4s ease;
  transition: transform 0.4s ease, -webkit-transform 0.4s ease;
  -webkit-transform: scale(0, 1);
  -ms-transform: scale(0, 1);
  transform: scale(0, 1);
  -webkit-transform-origin: right center;
  -ms-transform-origin: right center;
  transform-origin: right center;
}
.news-block-one .inner-box:hover .overlay-box {
  -webkit-transform: scale(1, 1);
  -ms-transform: scale(1, 1);
  transform: scale(1, 1);
  -webkit-transform-origin: left center;
  -ms-transform-origin: left center;
  transform-origin: left center;
}
.news-block-one .link-btn{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  font-size: 30px;
  font-weight: 600;
  color: #fff;
}
.news-block-one .lower-content{
  position: relative;
  padding: 30px 30px 37px 30px;
}
.news-block-one .author-image{
  position: absolute;
  top: -32px;
  right: 25px;
  width: 54px;
  height: 54px;
  border-radius: 50%;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);
}
.news-block-one .author-image img {
  width: 100%;
  border-radius: 50%;
  background-size: cover;
  object-fit: cover;
  object-position: center top;
  height: 100%;
}
.news-block-one .post-info li{
  position: relative;
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
  color: #999999;
  margin-right: 27px;
}
.news-block-one .post-info li:last-child{
  margin-right: 0px;
}
.news-block-one .post-info li:first-child{
  color: transparent !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
}
.news-block-one .post-info li:before{
  position: absolute;
  content: '';
  background: #bbc1cd;
  height: 24px;
  width: 1px;
  top: 4px;
  right: -15px;
}
.news-block-one .post-info li:last-child:before{
  display: none;
}
.news-block-one .post-info{
  position: relative;
  margin-bottom: 12px;
  height: 37px;
  overflow: hidden;
}
.news-block-one .lower-box{
  position: relative;
  padding: 13px 0px;
}
.news-block-one .admin{
  position: relative;
  font-size: 14px;
  line-height: 24px;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  color: #999999;
}
 .news-block-one  .lower-box .link-box a span {
  background: -webkit-linear-gradient(0deg, #0267ff, #0267ff 50%);
}
.news-block-one .lower-box .link-box a {
  background: #0267ff;
}
.news-block-one .lower-box .link-box a:hover {
  background: -webkit-linear-gradient(0deg, #ff6127, #febf5b 100%);
}
.news-block-one .lower-box .admin a{
  color: #999;
}
.news-block-one .lower-content h3 {
  font-size: 18px;
  line-height: 30px;
  font-weight: 500;
  margin: 17px 0 10px 0;
  height: 57px;
  overflow: hidden;
}
.lower-content .post-info li:first-child {
  background: #0267ff;
}
.news-block-one .lower-box .link-box{
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 1;
}
.news-block-one .lower-box .link-box a{
  position: relative;
  display: inline-block;
  font-size: 22px;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
  color: #fff;
  width: 50px;
  height: 50px;
  line-height: 50px;
  border-radius: 50%;
  z-index: 1;
  text-align: center;
}
.news-block-one .lower-box .link-box a span{
  position: absolute;
  right: 0%;
  top: 0px;
  width: 170px;
  height: 50px;
  line-height: 50px;
  padding: 0px 60px 0px 20px;
  color: transparent !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
  z-index: -1;
  font-size: 14px;
  font-weight: 700;
  font-family: 'Poppins', sans-serif;
  box-shadow: 0 10px 20px #c2c6d9;
  border-radius: 30px;
  opacity: 0;
  transform: scale(0);
  transition: all 500ms ease;
}
.news-block-one .lower-box .link-box a:hover span{
  opacity: 1;
  transform: scale(1);
}
/* ------------------------------------------------------------------------- */
/** featured-style-two **/
.featured-section.style-two{
  position: relative;
  padding: 67px 0 47px 0px;
}
.featured-section.style-two .feature-block-one{
  background: #fff;
  padding-bottom: 15px;
  margin-bottom: 40px;
}
.featured-section.style-two .feature-block-one:hover {
  padding-bottom: 55px;
  margin-bottom: -40px;
  background: none;
}
.featured-section .tab-btns li.active-btn{
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}
.featured-section.style-two .feature-block:first-child .feature-block-one .inner-box .icon-box{
  background: #0066ff; 
}
.featured-section.style-two .feature-block:nth-child(2) .feature-block-one .inner-box .icon-box{
  background: -webkit-linear-gradient(0deg, #ff5e0a, #ffce00 100%); 
}
.feature-block-one .inner-box .link a .featuredonsss {
  background: -webkit-linear-gradient(0deg, #842af1, #0e3bab 50%);
}
.featured-section.style-two .feature-block:nth-child(3) .feature-block-one .inner-box .icon-box{
  background: #5e6680; 
}
.featured-section.style-two .feature-block:last-child .feature-block-one .inner-box .icon-box{
  background: #536dfe; 
}

.featured-section.style-two .feature-block-one .inner-box .icon-box{
  box-shadow: 0 10px 20px rgba(1, 103, 255, 0.2);
}
.featured-section.style-two .feature-block:nth-child(2) .feature-block-one .inner-box .icon-box{
  box-shadow: 0 10px 20px rgba(255, 96, 14, 0.2);
}
.featured-section.style-two .feature-block:nth-child(3) .feature-block-one .inner-box .icon-box{
  box-shadow: 0 10px 20px rgba(94, 102, 128, 0.2);
}
.featured-section.style-two .feature-block:last-child .feature-block-one .inner-box .icon-box{
  box-shadow: 0 10px 20px rgba(83, 109, 254, 0.2);
}
.featured-section.style-two .feature-block-one .inner-box .icon-box .shadow-box{
  position: absolute;
  width: 0px;
  height: 0px;
  left: 40px;
  top: 30px;
  right: 0px;
  box-shadow: 0 0 25px 25px rgba(255, 255, 255, 0.75);
  border-radius: 50%;
}
.featured-section{
  position: relative;
  padding: 128px 0px;
}
.featured-section .sec-title h3{
  margin-bottom: 14px;
}
.featured-section .sec-title p{
  line-height: 30px;
}
.feature-block-one{
  position: relative;
  display: block;
  overflow: hidden;
  background: transparent;
  border-radius: 15px;
  padding: 65px 15px 55px 15px;
  text-align: center;
  transition: all 500ms ease;
}
.feature-block-one .inner-box .icon-box{
  position: relative;
  display: inline-block;
  font-size: 50px;
  color: #fff;
  width: 110px;
  height: 110px;
  line-height: 110px;
  text-align: center;
  border-radius: 50%;
  margin-bottom: 20px;
  box-shadow: 0 10px 20px rgba(4, 61, 164, 0.3);
  transition: all 500ms ease;
}
.feature-block-one .inner-box .text{
  position: relative;
  margin-bottom: 13px;
}
.feature-block-one .inner-box .link a{
  position: relative;
  display: inline-block;
  font-size: 15px;
  line-height: 30px;
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.feature-block-one .inner-box .link a .featuredonsss{
  position: relative;
  margin-left: 8px;
  top: 1px;
  left: 0px;
  color: #0e3bab;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: all 500ms ease;
}
.feature-block-one .inner-box .link a:hover .featuredonsss{
  left: 10px;
}
.feature-block-one .inner-box .link a:hover,
.feature-block-one .inner-box .link a:hover .featuredonsss{
  color: #0e3bab;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.feature-block-one .inner-box .link{
  position: relative;
  transform: scale(0,0);
  opacity: 0;
  transition: all 500ms ease;
}
.feature-block-one:hover .inner-box .link{
  transform: scale(1,1);
  opacity: 1;
}
.feature-block-one:before {
  content: '';
  width: 273px;
  height: 273px;
  border-radius: 50%;
  position: absolute;
  top: -148px;
  left: -2px;
  transform: scale(0);
  transition: all 500ms ease;
}
.feature-block-one:hover:before {
  transform: scale(1);
  transition: all 0.5s linear;
  transition-delay: 0.1s;
}
.feature-block-one:hover {
  border-color: transparent;
  box-shadow: 0 0px 20px rgba(0, 0, 0, 0.1);
}
.feature-block-one .hover-content {
  position: absolute;
  top:0;
  left:0;
  right: 0;
  bottom: 0;
  background: #fff;
  z-index: -3;
  opacity: 0;
  transition: all 0.3s linear;
}
.feature-block-one:hover .hover-content {
  opacity: 1;
}
.feature-block-one .hover-content:before {
  content: '';
  width: 493px;
  height: 493px;
  border-radius: 50%;
  position: absolute;
  top:-300px;
  left:-120px;
  z-index: 1;
  transform: translate(-50% , -50%);
  opacity: 0;
  transition: all 500ms ease;
}
.feature-block-one:hover .hover-content:before {
  opacity: 1;
  transform: translate(0% , 0%);
  transition: all 0.9s linear;
}
.feature-block-one .hover-content:after {
  content: '';
  width: 602px;
  height: 602px;
  border-radius: 50%;
  position: absolute;
  top:-350px;
  left:-180px;
  z-index: -1;
  transform: translate(-50% , -50%);
  opacity: 0;
  transition: all 500ms ease;
}
.feature-block-one:hover .hover-content:after {
  opacity: 1;
  transform: translate(0% , 0%);
  transition: all 1.3s linear;
}
.feature-block-one:before{
  background: rgba(216, 88, 245, 0.1);
}
.feature-block-one .hover-content:before{
  background: rgba(216, 88, 245, 0.1);
}
.feature-block-one .hover-content:after{
  background: rgba(164, 97, 232, 0.1);
}
.feature-block-one .link a {
  background: -webkit-linear-gradient(0deg, #842af1, #0e3bab 50%);
}
.feature-block-one h4 {
  position: relative;
  display: block;
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
  margin-bottom: 10px;
}
/* ------------------------------------------------------------------------- */
/** bubble-dotted **/

.bubble-dotted {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  pointer-events: none;
}

.bubble-dotted .dotted{
  position: absolute;
  border-radius: 50%;
  z-index: 7;
}

.bubble-dotted .dotted-1{
  width: 11px;
  height: 11px;
  left: 100px;
  top: 50%;
  -webkit-animation: animateBubble 15s linear infinite,sideWays 2s ease-in-out infinite alternate;
  -moz-animation: animateBubble 15s linear infinite,sideWays 2s ease-in-out infinite alternate;
  animation: animateBubble 15s linear infinite,sideWays 2s ease-in-out infinite alternate;
}

.bubble-dotted .dotted-2{
  width: 7px;
  height: 7px;
  left: 240px;
  top: 40%;
  -webkit-animation: animateBubble 10s linear infinite,sideWays 4s ease-in-out infinite alternate;
  -moz-animation: animateBubble 10s linear infinite,sideWays 4s ease-in-out infinite alternate;
  animation: animateBubble 10s linear infinite,sideWays 4s ease-in-out infinite alternate;
}

.bubble-dotted .dotted-3{
  width: 11px;
  height: 11px;
  left: 460px;
  top: 30%;
  -webkit-animation: animateBubble 18s linear infinite,sideWays 2s ease-in-out infinite alternate;
  -moz-animation: animateBubble 18s linear infinite,sideWays 2s ease-in-out infinite alternate;
  animation: animateBubble 18s linear infinite,sideWays 2s ease-in-out infinite alternate;
}

.bubble-dotted .dotted-4{
  width: 16px;
  height: 16px;
  left: 430px;
  top: 90%;
  -webkit-animation: animateBubble 12s linear infinite,sideWays 3s ease-in-out infinite alternate;
  -moz-animation: animateBubble 12s linear infinite,sideWays 3s ease-in-out infinite alternate;
  animation: animateBubble 12s linear infinite,sideWays 3s ease-in-out infinite alternate;
}

.bubble-dotted .dotted-5{
  width: 6px;
  height: 6px;
  left: 50%;
  top: 50%;
  -webkit-animation: animateBubble 19s linear infinite,sideWays 4s ease-in-out infinite alternate;
  -moz-animation: animateBubble 19s linear infinite,sideWays 4s ease-in-out infinite alternate;
  animation: animateBubble 19s linear infinite,sideWays 4s ease-in-out infinite alternate;
}

.bubble-dotted .dotted-6{
  width: 9px;
  height: 9px;
  left: 70%;
  top: 230px;
  -webkit-animation: animateBubble 11s linear infinite,sideWays 2s ease-in-out infinite alternate;
  -moz-animation: animateBubble 11s linear infinite,sideWays 2s ease-in-out infinite alternate;
  animation: animateBubble 11s linear infinite,sideWays 2s ease-in-out infinite alternate;
}

.bubble-dotted .dotted-7{
  width: 6px;
  height: 6px;
  left: 65%;
  top: 30%;
  -webkit-animation: animateBubble 10s linear infinite,sideWays 2s ease-in-out infinite alternate;
  -moz-animation: animateBubble 10s linear infinite,sideWays 2s ease-in-out infinite alternate;
  animation: animateBubble 10s linear infinite,sideWays 2s ease-in-out infinite alternate;
}

.bubble-dotted .dotted-8{
  width: 6px;
  height: 6px;
  left: 85%;
  top: 35%;
  -webkit-animation: animateBubble 12s linear infinite,sideWays 3s ease-in-out infinite alternate;
  -moz-animation: animateBubble 12s linear infinite,sideWays 3s ease-in-out infinite alternate;
  animation: animateBubble 12s linear infinite,sideWays 3s ease-in-out infinite alternate;
}

.bubble-dotted .dotted-9{
  width: 13px;
  height: 13px;
  left: 90%;
  top: 40%;
  -webkit-animation: animateBubble 19s linear infinite,sideWays 4s ease-in-out infinite alternate;
  -moz-animation: animateBubble 19s linear infinite,sideWays 4s ease-in-out infinite alternate;
  animation: animateBubble 19s linear infinite,sideWays 4s ease-in-out infinite alternate;
}

.bubble-dotted .dotted-10{
  width: 12px;
  height: 12px;
  left: 80%;
  top: 70%;
  -webkit-animation: animateBubble 16s linear infinite,sideWays 2s ease-in-out infinite alternate;
  -moz-animation: animateBubble 16s linear infinite,sideWays 2s ease-in-out infinite alternate;
  animation: animateBubble 16s linear infinite,sideWays 2s ease-in-out infinite alternate;
}

@-webkit-keyframes animateBubble{0%{margin-top:20%}100%{margin-top:-30%}}@-moz-keyframes animateBubble{0%{margin-top:20%}100%{margin-top:-30%}}@keyframes animateBubble{0%{margin-top:20%}100%{margin-top:-30%}}@-webkit-keyframes sideWays{0%{margin-left:0}100%{margin-left:25px}}@-moz-keyframes sideWays{0%{margin-left:0}100%{margin-left:25px}}@keyframes sideWays{0%{margin-left:0}100%{margin-left:25px}}

.bubble-dotted .dotted-1{
  background: -webkit-linear-gradient(0deg, #58f106, #23c10e 50%);
}

.bubble-dotted .dotted-2{
  background: -webkit-linear-gradient(0deg, #862ff1, #163fae 50%);
}

.bubble-dotted .dotted-3{
  background: -webkit-linear-gradient(0deg, #ff5d06, #ffc806 50%);
}

.bubble-dotted .dotted-4{
  background: -webkit-linear-gradient(0deg, #d406b7, #fc1a66 50%);
}

.bubble-dotted .dotted-5{
  background: -webkit-linear-gradient(0deg, #fe5f12, #fec412 50%);
}

.bubble-dotted .dotted-6{
  background: -webkit-linear-gradient(0deg, #d622bf, #fb196a 50%);
}

.bubble-dotted .dotted-7{
  background: -webkit-linear-gradient(0deg, #60f112, #30c719 50%);
}

.bubble-dotted .dotted-8{
  background: -webkit-linear-gradient(0deg, #fe5f12, #fec412 50%);
}

.bubble-dotted .dotted-9{
  background: -webkit-linear-gradient(0deg, #842af1, #0f40aa 50%);
}

.bubble-dotted .dotted-10{
  background: -webkit-linear-gradient(0deg, #58f106, #23c10f 50%);
}

/* ------------------------------------------------------------------------- */
.aaa .news-block-one .inner-box{
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  background-image: url(./Img/delete/slide-1.jpg);
  box-shadow: none;
}
.aaa .news-block-one {
  position: relative;
  margin: 30px 0 7px;
}
.aaa .news-block-one .lower-content h3{ 
  margin: 0;
  font-size: 22px;
  line-height: 30px;
  font-weight: 500;
  height: 60px;
}
.aaa .news-block-one .lower-content {
  position: relative;
  padding: 13px 10px 10px 30px;
}
.aaa .hhhsss {
  height: 127px;
  overflow: hidden;
}
.aaa .news-block-one .admin {
  font-size: 16px;
  text-align: center;
  color: #ffffff;
}
.aaa .news-block-one .inner-box .image-box {
  margin: 0;
  min-height: 270px;
  max-height: 270px;
}
.aaa .lower-box2 {
  position: absolute;
  bottom: 0;
  left: 17px;
}
.aaa .sec-title {
  margin-bottom: 0px;
}
.aaa .news-block-one .lower-box {
  position: absolute;
  padding: 0;
  display: none; 
  right: 17px;
  bottom: 4px;
}
.aaa .news-block-one:hover .lower-box {
   display: block; 
}
.aaa .news-block-one .author-image {
  top: 97px;
  right: -23px;
}
.aaa .link-box3 a span {
  position: relative;
  float: right;
  line-height: 50px;
  white-space: nowrap;
  padding: 0px 20px 0px 0px;
  color: transparent !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
  z-index: -1;
  font-size: 14px;
  font-weight: 700;
  font-family: 'Poppins', sans-serif;
  transition: all 500ms ease;
}
.aaa .link-box3 {
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 1;
}
.aaa .link-box3 a span {
  background: -webkit-linear-gradient(0deg, #bf3b07, #fe7230 50%);
  font-size: 15px;
}
.aaa .news-block-one .inner-box .image-box img {
  height: 270px;
}
.aaa .news-block-one .post-info {
  position: relative;
  margin-bottom: 7px;
  margin-top: 3px;
}
.aaa .news-block-one .post-info li {
  line-height: 33px;
}
.aaa .news-block-one .lower-box .link-box a{
  background: -webkit-linear-gradient(0deg, #ff6127, #febf5b 100%);
}
.aaa .news-block-one .lower-box .link-box a span {
  opacity: 1;
  transform: scale(1);
}
.aaa .news-block-one .lower-box .link-box a span {
  background: -webkit-linear-gradient(0deg, #bf3b07, #fe7230 50%);
  font-size: 15px;
}

/* ------------------------------------------------------------------------- */
.copyrights{
  position: relative;
  width: 100%;
  float: left;
  padding: 10px 0px 10px 0px;
  background-color: #000000;
}
.copyrights p{
  margin: 0;
  font-size: 17px;
  letter-spacing: 1px;
}
.copyrights a{
  font-size: 17px;
  letter-spacing: 1px;
}
.green{
  color: #fd6500;
}
.white{
  text-align: center;
  display: block;
  color: white;
}
.footer{
  width: 100%;
  float: left;
  z-index: 999;
  background: #eef2fe;
  padding: 60px 0 60px 0;
}
.footer h3 {
  margin-bottom: 27px;
}
.footer ul {
  padding: 0;
}
.footer li {
  margin: 0 0 17px 0;
  list-style: none;
}
.footer p {
  font-size: 17px;
  line-height: 33px;
  padding-right: 17px;
}
.footer a {
  color: #000000;
}
.footer .content-boxsss li {
  margin: 0 0 37px 0;
  list-style: none;
  font-size: 17px;
}
.footer .chooseus3facebook {
  color: #2b4a8b;
  box-shadow: 0px 3px 12px 0px #2b4a8b;
}
.footer .chooseus3twitter {
  color: #1f94d9;
  box-shadow: 0px 3px 12px 0px #1f94d9;
}
.footer .chooseus3linkedin {
  color: #10598c;
  box-shadow: 0px 3px 12px 0px #10598c;
}
.footer .content-box{
  margin-bottom: 27px;
}
/* ------------------------------------------------------------------------- */

.add-block {
  position: relative;
  margin-bottom: 30px;
  margin-top: 37px;
}
.add-block .inner-box {
  position: relative;
}
.add-block .inner-box .image-column {
  position: relative;
  padding: 0px;
  float: left;
}
.add-block .inner-box .image-column .inner-column {
  position: relative;
  margin-right: -35px;
}
.add-block .inner-box .image-column .inner-column .image {
  position: relative;
  overflow: hidden;
  background-color: #000000;
}
.add-block .inner-box .image-column .inner-column .image .sale {
  position: absolute;
  left: 15px;
  top: 15px;
  z-index: 1;
  color: #fff;
  padding: 2px 14px;
  border-radius: 50px;
  display: inline-block;
  background-color: #00c784;
  max-width: 200px;
  overflow: hidden;
  height: 29px;
  word-break: break-all;
}
.add-block .inner-box .image-column .inner-column .image img {
  position: relative;
  width: 100%;
  display: block;
  -webkit-transition: all 600ms ease;
  -ms-transition: all 600ms ease;
  -o-transition: all 600ms ease;
  -moz-transition: all 600ms ease;
  transition: all 600ms ease;
  height: 253px;
  background-size: cover;
  object-fit: cover;
  object-position: center center;
}
.add-block .inner-box .image-column .inner-column h4{
    font-size: 20px;
    height: 27px;
    overflow: hidden;
}
.add-block .inner-box .content-column {
  position: relative;
  padding: 0px;
  float: left;
  transform: translateY(37%);
  margin-bottom: 77px;
}
.add-block .inner-box .content-column .inner-column {
  position: relative;
  padding: 20px 25px;
  margin-left: -97px;
  background-color: #ffffff;
  box-shadow: 0px 0px 15px rgba(0,0,0,0.10);
}
.add-block .inner-box .content-column .post-meta {
  position: relative;
}
.add-block .inner-box .content-column .post-meta li {
  position: relative;
  display: inline-block;
  color: #211347;
  font-size: 14px;
  opacity: 0.7;
  margin-bottom: 7px;
}
.add-block .inner-box .content-column .post-meta li a span {
  margin-right: 4px;
}
.add-block .inner-box .content-column h4 a {
  position: relative;
  color: #000000;
  -webkit-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  transition: all 300ms ease;
}
.add-block .inner-box .content-column .add-info {
  position: relative;
  margin-top: 12px;
  color: #ff8667;
  font-weight: 500;
  font-size: 16px;
  font-family: 'Rubik', sans-serif;
}
.pull-left {
  float: left;
}
.add-block .inner-box .content-column .add-info span {
  position: relative;
  margin-left: 0;
  color: #ff6d11;
  font-size: 16px;
  text-decoration: none;
  margin-top: 3px;
  display: block;
}
.pull-right {
  float: right;
}
/* ------------------------------------------------------------------------- */

.fun-facts {
  position: relative;
  background: #fff;
  padding: 60px 0px;
}
.fun-facts .map-shap {
  position: absolute;
  left: 0px;
  top: 60px;
  right: 0px;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 100%;
  background-image: url(./Img/delete/map-shap-1.png);
}
.fun-facts .sec-title {
  margin-bottom: 55px;
}
.fun-facts .sec-title .title .chooseus{
  background: linear-gradient(90deg, var(--c1, #ff5c07), var(--c2, #ffcf06 ) 50%, var(--c1, #ff5c07)) var(--x, 0)/ 200%;
  
  position: absolute;
  left: 0px;
  top: 0px;
  width: 35px;
  height: 35px;
  line-height: 35px;
  color: #fff;
  text-align: center;
  border-radius: 50%;
}
.fun-facts .sec-title h3 {
  margin-bottom: 14px;
}
.fun-facts .block-column:first-child, .fun-facts .block-column:nth-child(3) {
  padding-top: 40px;
}
.fun-facts .block-column:first-child, .fun-facts .block-column:nth-child(3) {
  padding-top: 40px;
}
.counter-block-one {
  position: relative;
  display: block;
  text-align: center;
}
.counter-block-one .counter-block .count-outer {
  position: relative;
  display: flex;
  width: 130px;
  height: 130px;
  background: #fff;
  text-align: center;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin: 0 auto;
  box-shadow: 0 10px 20px rgba(254, 22, 96, 0.2);
}
.counter-block-one .counter-block .count-outer span {
  position: relative;
  display: inline-block;
  font-size: 45px;
  line-height: 45px;
  font-weight: 700;
  font-family: 'Poppins', sans-serif;
  color: transparent;
  -webkit-background-clip: text!important;
  -webkit-text-fill-color: transparent;
}
.counter-block-one .counter-block .count-outer span {
  background: -webkit-linear-gradient(0deg, #d100b8, #fc1564 50%);
}
.counter-block-one .counter-block .count-outer span:last-child {
  font-weight: 400;
  color: transparent !important;
  background: -webkit-linear-gradient(0deg, #161616, #161616 50%) !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
}
.counter-block-one .counter-block h3 {
  font-weight: 400;
  margin-top: 21px;
  position: relative;
  display: block;
  font-size: 22px;
  line-height: 30px;
  margin-bottom: 10px;
}
.counter-block-one .counter-block h3 span {
  font-weight: 700;
}
.fun-facts .block-column:nth-child(2) .counter-block-one .counter-block .count-outer span {
  background: -webkit-linear-gradient(0deg, #ff5500, #ffcd00 50%);
}
.fun-facts .block-column:nth-child(3) .counter-block-one .counter-block .count-outer span {
  background: -webkit-linear-gradient(0deg, #832af0, #053da5 50%);
}
.fun-facts .block-column:last-child .counter-block-one .counter-block .count-outer span {
  background: -webkit-linear-gradient(0deg, #55f100, #1bbd09 50%);
}
.fun-facts .block-column:nth-child(2) .counter-block-one .counter-block .count-outer {
  box-shadow: 0 10px 20px rgba(255, 87, 0, 0.2);
}
.fun-facts .block-column:nth-child(3) .counter-block-one .counter-block .count-outer {
  box-shadow: 0 10px 20px rgba(71, 51, 204, 0.2);
}
.fun-facts .block-column:last-child .counter-block-one .counter-block .count-outer {
  box-shadow: 0 10px 20px rgba(64, 222, 3, 0.2);
}
/* ------------------------------------------------------------------------- */
 .chooseus{
  background: linear-gradient(90deg, var(--c1, #ff5c07), var(--c2, #ffcf06 ) 50%, var(--c1, #ff5c07)) var(--x, 0)/ 200%;
  
  position: absolute;
  left: 0px;
  top: 0px;
  width: 35px;
  height: 35px;
  line-height: 35px;
  color: #fff;
  text-align: center;
  border-radius: 50%;
}
.chooseus3 {
  overflow: visible;
  color: #ff7507;
  margin-right: 17px;
  height: 27px;
  width: 27px!important;
  vertical-align: -7px;
}
/* ------------------------------------------------------------------------- */

.fun-factsbussiness {
  position: relative;
  background: #fff;
  padding: 77px 0 47px 0;
}
.fun-factsbussiness .sec-title {
  margin-bottom: 55px;
}
.fun-factsbussiness .sec-title .title .chooseus{
  background: linear-gradient(90deg, var(--c1, #ff5c07), var(--c2, #ffcf06 ) 50%, var(--c1, #ff5c07)) var(--x, 0)/ 200%;
  
  position: absolute;
  left: 0px;
  top: 0px;
  width: 35px;
  height: 35px;
  line-height: 35px;
  color: #fff;
  text-align: center;
  border-radius: 50%;
}
.fun-factsbussiness .sec-title h3 {
  margin-bottom: 14px;
}
 .city-card {
  height: 277px;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 10px 20px rgba(71, 51, 204, 0.2);
  text-align: center;
  margin-bottom: 30px;
  background-size: cover;
  object-fit: cover;
  object-position: center center;
}
 .title-content {
  text-align: center;
  margin: 70px 0 0 0;
  position: absolute;
  z-index: 1;
  width: 100%;
  top: 0;
  left: 0;
}
.city-card h3 {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 2px;
  color: #fff;
  margin-bottom: 0;
  line-height: 37px;
  padding: 0 10px 0 10px;
  text-shadow: 3px 3px 7px black;
}
.city-card:hover h3{
  opacity: 0;
}
.city-card:hover .card-info {
  opacity: 1;
  bottom: 0px;
  height: 257px;
  overflow: hidden;
  transition: all 500ms ease;
}
.city-card .card-info p{
  transition: all 500ms ease;
}
.city-card .card-info p{
    color: white;
    font-size: 18px;
    line-height: 33px;
    height: 157px;
    overflow: hidden;
}
.city-card:hover  .utility-info{
  opacity: 0;
}
.city-card:hover .card-info .theme-btn {
  margin-right: 0px;
  margin-top: 27px;
}
.card-info {
  width: 100%;
  position: absolute;
  left: 0;
  margin: 0 auto;
  padding: 0 27px;
  color: #dce3e7;
  font-size: 18px;
  line-height: 33px;
  z-index: 20;
  opacity: 0;
  transition: bottom .3s , opacity .3s cubic-bezier(.33,.66,.66,1);
}
 .utility-info {
  position: absolute;
  bottom: 30px;
  left: 30px;
  z-index: 20;
}
 .utility-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  width: 100%;
}
 .utility-list li {
  display: inline-block;
  color: #dce3e7;
  text-transform: capitalize;
  font-size: 16px;
  margin-left: 7px;
}
 .city-card .gradient-overlay {
  background-image: -webkit-linear-gradient(transparent 0,rgba(0,0,0,.6) 21%);
  background-image: -moz-linear-gradient(transparent 0,rgba(0,0,0,.6) 21%);
  background-image: -o-linear-gradient(transparent 0,rgba(0,0,0,.6) 21%);
  background-image: linear-gradient(transparent 0,rgba(0,0,0,.6) 21%);
  position: absolute;
  top: 350px;
  left: 0;
  z-index: 15;
}
 .city-card:hover .color-overlay {
  background: rgba(0,0,0,.8);
}
 .city-card .color-overlay {
  background: rgba(0,0,0,.4);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transition: background .3s cubic-bezier(.33,.66,.66,1);
  -moz-transition: background .3s cubic-bezier(.33,.66,.66,1);
  -o-transition: background .3s cubic-bezier(.33,.66,.66,1);
  transition: background .3s cubic-bezier(.33,.66,.66,1);
}
/* ------------------------------------------------------------------------- */
.members{
  padding: 77px 0 77px 0;
  background-repeat: repeat;
  background-position: top center;
  background-size: cover;
  z-index: -7;
  background-image: url(./Img/delete/slide-1.jpg);
}
.memberstabsss{
  background-color: #fff;
  border: 1px solid #f0f0f0;
  border-radius: 4px;
  margin-bottom: 30px;
  overflow: hidden;
  box-shadow: 0 0.5rem 1rem 0 rgba(44, 51, 73, 0.1);
  min-height: 100vh;
}
.memberstabsss li:hover {
  color: #fd6500!important;
}
.memberstabsss li {
  cursor: pointer;
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0rem 1.25rem;
  font-size: 17px;
  text-transform: capitalize;
  height: 77px;
  border-bottom: 1px solid #edf1f7;
  font-family: "Poppins",sans-serif;
  font-weight: 500;
  color: #3e3d3d;
  letter-spacing: 1px;
}
.memberstabsss li svg{
  -webkit-box-flex: 0;
  -ms-flex: 0 0 25px;
  flex: 0 0 25px;
  max-width: 25px;
  width: 25px;
  font-size: 18px;
  font-weight: 500;
  margin-right: 10px;
}
.memberstabsss .active {
  color: #fd6500!important;
}
.card {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flow-root;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  -webkit-box-shadow: 0 0.5rem 1rem 0 rgba(44, 51, 73, 0.1);
  box-shadow: 0 0.5rem 1rem 0 rgba(44, 51, 73, 0.1);
  border: none;
  border-radius: 3px;
  min-height: 100vh;
}
/* ------------------------------------------------------------------------- */

.form-control {
  border-color: #dbdbdb;
  font-size: 13px;
  height: 40px;
  display: block;
  width: 100%;
  height: calc(1.7em + 0.75rem + 10px);
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  outline: none;
}
.form-group label {
  color: #3c4858;
  font-size: 0.874rem;
}
.form-control:focus {
  border-color: #6e00ff;
  box-shadow: none;
  box-shadow: none;
}
/* ------------------------------------------------------------------------- */
.login-1 {
  padding: 90px 0 77px 0;
  background-repeat: repeat;
  background-position: center;
  background-size: cover;
  z-index: -7;
  background-attachment: fixed;
  background-image: url(./Img/delete/slide-1.jpg);
 
}
.login-forms h1 {
  float: left;
  display: block;
  width: 100%;
  text-align: left;
  margin-bottom: 17px;
  font-size: 36px;
  line-height: 47px;
  color: #161616;
  font-weight: 700;
}
.login-forms p {
  text-align: left;
  float: left;
  display: block;
  width: 100%;
  margin-bottom: 17px;
}
.login-1 h6 {
  cursor: pointer;
  clear: both;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 17px;
  text-align: center;
  color: #1a3ab2;
}
.login-1 h6:hover {
  color: rgb(255, 110, 7);
}
.logsss3 {
  display: flex;
  flex-direction: column;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  box-shadow: 0 0.5rem 1rem 0 rgba(44, 51, 73, 0.1);
  border: none;
  border-radius: 3px;
  padding: 47px;
}
.login-1 .form-group label {
  font-size: 16px;
}
.login-1 .theme-btn {
  float: left;
  margin-right: 0px;
  margin-top: 10px;
}
.theme-btn.style-three {
  background: linear-gradient(90deg, var(--c1, #ff5700), var(--c2, #ffd000 ) 50%, var(--c1, #ff5700)) var(--x, 0)/ 200%;
}

.theme-btn.style-three:before {
  background: linear-gradient(90deg, var(--c1, #d301b4), var(--c2, #ff165e ) 50%, var(--c1, #d301b4)) var(--x, 0)/ 200%;
}
.theme-btn.style-three:after {
  background: linear-gradient(90deg, var(--c1, #d301b4), var(--c2, #ff165e ) 50%, var(--c1, #d301b4)) var(--x, 0)/ 200%;
}
.error{
  color: rgb(255, 17, 0);
}
/* ------------------------------------------------------------------------- */
.breadcrumbs {
  padding: 130px 0 77px 0;
  background-color: #f7f4ed;
  background-repeat: repeat;
  background-position: top center;
  background-size: contain;
  position: relative;
  background-image: url(./Img/delete/first-bg.png);
}
.breadcrumbs .content-box h1 {
  position: relative;
  font-size: 40px;
  line-height: 50px;
  font-weight: 700;
  color: #0082ff;
  margin-bottom: 13px;
  text-shadow: 0 3px 7px rgba(0, 0, 0, 0.14);
  text-align: center;
}
.breadcrumbs .content-box .bread-crumb li {
  position: relative;
  display: inline-block;
  font-size: 15px;
  color: #0082ff;
  padding-right: 22px;
  margin-right: 6px;
}
.breadcrumbs .content-box .bread-crumb li:before {
  position: absolute;
  content: '';
  width: 7px;
  height: 7px;
  border-radius: 50%;
  top: 9px;
  right: 4px;
  background: #fa6342;
}
.breadcrumbs .content-box .bread-crumb li a {
  color: #0082ff;
}
.breadcrumbs ul{
  margin: 0 auto;
  display: table;
}
.breadcrumbs .content-box .bread-crumb li:last-child {
  padding: 0px;
  margin: 0px;
}
.breadcrumbs .content-box .bread-crumb li:last-child:before {
  display: none;
}
/* ------------------------------------------------------------------------- */
.aaa4{
    padding: 31px 25px 27px 36px;
    border-radius: 10px 10px 10px 10px;
    box-shadow: 11px 10px 38px 0px rgba(0, 0, 0, 0.1);
}
.select-icon {
  display: table-cell;
  vertical-align: top;
  text-align: left;
  padding-left: 0px;
  padding-top: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
  width: 24px;
  height: 24px;
}

.select-txt {
  display: table-cell;
  vertical-align: middle;
  word-wrap: break-word;
  height: 60px;
  text-align: left;
  padding-left: 15px;
  font-size: 12pt;
}
.select-logo {
  padding-right: 0px;
  vertical-align: top;
      right: 35px;
    position: absolute;
}
.select-logo-sub {
  display: table-cell;
  vertical-align: middle;
}
.pymt-type-name {
  font-weight: 500;
  font-size: 20px;
  padding-bottom: 0px;
  color: #3c444c;
  margin-bottom: 7px!important;
}
.pymt-type-desc {
  width: 70%!important;
  font-size: 16px;
  color: #999;
}
.payment-row {
  background-color: #f5f6fa;
  width: 100%;
  margin: 0px 0px 0px 0px;
  padding: 22px 30px 10px 30px;
  text-align: left;
  display: table;
  position: relative;
}
.aaa5 {
  width: 100%;
  clear: both;
  float: left;
  position: relative;
  margin-top: 27px;
  border: 1px solid darkgrey;
  padding: 37px;
  border-radius: 7px;
  box-shadow: 0px 0px 4px 0px #c7c7c7;
}
.aaa5 h4 {
  float: left;
  display: block;
  width: 100%;
  text-align: left;
  margin-bottom: 37px;
  font-size: 27px;
  line-height: 47px;
  color: #0679e8;
  font-weight: 500;
  letter-spacing: 1px;
  text-shadow: 2px 1px 1px #cdcdce;
}
.payment-titleqqq{
  color: #ff7207;
  float: left;
  display: block;
  width: 100%;
  text-align: left;
  font-size: 23px;
  line-height: 47px;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: capitalize;
}
.login-1 .radio:checked+.radio__label {
  color: hsla(0,0%,100%,.87);
  background-color: #ff7207;
}
.login-1 .pricing-block-two .pricing-table .choice-box h6 {
  margin: 0!important;
}

.login-1 .radio__label:hover {
  color: #3c91e6;
}
.login-1 .radio__label {
  height: 50px;
  cursor: pointer;
  margin: 27px auto 0;
  position: relative;
  display: inline-block;
  overflow: hidden;
  line-height: 30px;
  font-weight: 700;
  font-family: 'Poppins', sans-serif;
  padding: 8px 40px;
  border-radius: 30px;
  text-align: center;
  z-index: 1;
  background-color: white;
  box-shadow: 0 5px 20px rgba(0,0,0,0.3) !important;
}
.radio__label span {
  margin-left: 13px;
  font-size: 17px;
  letter-spacing: 1px;
  font-weight: 600;
  line-height: 32px;
}
.login-1 .radio:checked+.radio__label:before {
  border-color: #fff;
}
.login-1 .radio__label:before {
  left: 10px;
  top: 10px;
  width: 30px;
  height: 30px;
  border: 2px solid #ff7b06;
  border-radius: 100%;
}
.login-1 .radio__label:after, .login-1 .radio__label:before {
  position: absolute;
  content: "";
  -webkit-transition: border-color .2s ease,-webkit-transform .2s ease;
  transition: border-color .2s ease,-webkit-transform .2s ease;
  transition: transform .2s ease,border-color .2s ease;
  transition: transform .2s ease,border-color .2s ease,-webkit-transform .2s ease;
  box-sizing: border-box;
}
.login-1 .radio:checked+.radio__label:after {
  -webkit-transform: scale(1);
  transform: scale(1);
}
.login-1 .radio__label:after {
  top: 15px;
  left: 15px;
  width: 20px;
  height: 20px;
  -webkit-transform: scale(0);
  transform: scale(0);
  background-color: #fff;
  border-radius: 100%;
}
.login-1 .radio__label:hover:before {
  border-color: #fb872b;
}
.login-1 .radio {
  display: inline;
  opacity: 0;
  width: 0;
  margin: 0;
  overflow: hidden;
  -webkit-appearance: none;
}
.aaa4 h3{
    text-align: center;
    display: block;
    width: 100%;
    margin-bottom: 17px;
    letter-spacing: 1px;
    font-size: 27px;
}
.aaa5 input[id^="radio"]:checked + label {
  background-image: url(./Img/radio-active.png);
  height: 26px;
  width: 26px;
  display: inline-block;
  padding: 0 0 0 0px;
  cursor: pointer;
  border-radius: 50%;
  border: 1px solid #7dc855;
}
.aaa5 input[id^="radio"] {
  display: none;
}
.aaa5 input[id^="radio"] + label {
  height: 26px;
  width: 26px;
  display: inline-block;
  padding: 0 0 0 0px;
  border-radius: 50%;
  font-weight: 700;
  font-size: 14px;
  float: left;
  color: #ccc;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  background: #fff;
  margin-bottom: 2px;
  border-bottom: 2px solid #eee;
  background-color: #ffffff;
  border: 1px solid #656565;
}
.payment-row-last {
  margin-left: 30px;
  margin-right: 30px;
  width: 100%;
}
/* ------------------------------------------------------------------------- */
.successs{
  padding: 90px 0 77px 0;
  background-repeat: repeat;
  background-position: center;
  background-size: cover;
  z-index: -7;
  background-attachment: fixed;
  background-image: url(./Img/delete/slide-1.jpg);
  min-height: 100vh;
}
.successs h2 {
  float: none;
  display: block;
  width: 100%;
  text-align: center;
  margin-bottom: 37px;
  font-size: 33px;
  line-height: 57px;
  color: #ff6b07;
  font-weight: 500;
  letter-spacing: 1px;
  text-shadow: 2px 1px 1px #d6d7d8;
}
.successs h3 {
  float: none;
  display: block;
  width: 100%;
  text-align: left;
  margin-bottom: 7px;
  font-size: 20px;
  line-height: 57px;
  color: #0778e4;
  font-weight: 500;
  letter-spacing: 1px;
  text-shadow: 2px 1px 1px #d6d7d8;
  border-bottom: 1px solid lavender;
}
.successs .logsss3 {
  display: block;
}
/* ------------------------------------------------------------------------- */
.bbb{
    padding-top: 20px;
    transform: translateY(0);
    transition: 0.5s;
    position: relative;
}
.bbb .elementor {
  padding: 31px 17px 27px 17px;
  border-radius: 10px 10px 10px 10px;
  box-shadow: 7px 7px 17px 0px rgba(140, 137, 137, 0.62);
  background-color: #FFFFFF;
  transition: 0.4s;
  position: relative;
  background: -webkit-linear-gradient(0deg,#00bdff,#0082ff 50%);
}
/* ------------------------------------------------------------------------- */
.bbb2 .gold-section {
  position: relative;
  padding: 0;
}
.bbb2 .central-meta{
  margin-top: 30px;
}
.previewimg {
  max-width: 137px;
  margin-bottom: 17px;
  box-shadow: 3px 3px 10px #00000070;
}
.removeqqq {
  margin: 0 0 0 27px;
  background: -webkit-gradient(linear,left top,left bottom,color-stop(0,#f35c15),color-stop(0,#f99641),color-stop(99%,#f35c15));
  background: linear-gradient(180deg,#f35c15,#f99641 0,#f35c15 99%);
  color: #fff;
  outline: none;
  padding: 7px 17px;
  font-size: 16px;
}
textarea {
  border: 1px solid gainsboro;
  background: #fbfbfb;
  padding: 10px;
  width: 100%;
}
.bbb2 h3 {
  color: #ff6b07;
  margin-bottom: 13px;
  margin-top: 17px;
  font-size: 26px;
  font-weight: 600;
  letter-spacing: 1px;
  border-bottom: 1px solid #dbdbe4;
  display: block;
  width: 100%;
  padding-bottom: 10px;
  background-color: #eef2fe;
  padding: 10px;
}
.login-1 .bbb2 .form-group label {
  font-size: 17px;
  margin: 17px 0px 10px 0px;
  letter-spacing: 1px;
}
.adverts-form-required{
  color: #e63a04;
}
.login-1 .bbb2 .theme-btn {
  float: right;
  margin-right: 0px;
  margin-top: 27px;
}
.bbb2 .sec-title .title {
  float: left;
  margin-bottom: 0px;
}
/* ------------------------------------------------------------------------- */
.Toastify__toast--info {
  background: #07bc0c;
  background: linear-gradient(90deg, var(--c1, #842af1), var(--c2, #033da4 ) 50%, var(--c1, #842af1)) var(--x, 0)/ 200%;
}
/* ------------------------------------------------------------------------- */
.card .gold-section {
  padding: 0px 0 0 0px;
}
.gold-sectionview{ 
  position: relative;
  width: 100%;
}
.editsss {
  float: right;
  position: absolute;
  left: -43px;
  top: -52px;
}
.public-DraftEditor-content {
  min-height: 300px;
}
.rdw-editor-main {
  background-color: #edf2f6;
  border: 1px solid #edf2f6;
  margin-bottom: 17px;
}
.public-DraftStyleDefault-ltr {
  margin-left: 17px!important;
  margin-right: 17px!important;
}
.desc p {
  margin-bottom: 27px;
  letter-spacing: 0.7px;
  line-height: 33px;
}
.gold-sectionview h2{
    position: relative;
    font-size: 36px;
    line-height: 47px;
    color: #161616;
    font-weight: 700;
    margin-bottom: 17px;
}
.awsewesss{
  margin-top: 27px;
}
.gold-sectionview figure {
  margin: 0;
}
.gold-sectionview .author-image {
  position: absolute;
  top: -90px;
  left: 0px;
  width: 87px;
  height: 87px;
  border-radius: 50%;
  box-shadow: 0 10px 10px rgba(0,0,0,.2);
}
.gold-sectionview .author-image img {
  width: 100%;
  border-radius: 50%;
}
.gold-sectionview .lower-content {
  position: relative;
  padding: 0;
}
.gold-sectionview li {
  list-style: none;
  padding: 0px;
  margin: 0px;
  border-bottom: 1px solid #e0e0e4;
  margin-bottom: 17px;
  padding-bottom: 17px;
}
.cansss {
  margin-right: 47px!important;
}
.desc2{
  height: 97px;
  overflow: hidden;
}
.change-avatar img {
  border-radius: 7px;
  height: 147px;
  width: 147px;
  object-fit: cover;
  margin-bottom: 17px;
  object-position: center top;
}
.card-title2 {
  margin-bottom: 37px;
  position: relative;
  font-size: 30px;
  line-height: 47px;
  color: #161616;
  font-weight: 600;
}
.elementor h3{
    font-size: 25px;
    color: white;
    text-align: center;
    letter-spacing: 1px;
    margin-bottom: 17px;
    font-weight: 600;
}
.elementor h4{
    font-size: 25px;
    color: white;
    text-align: center;
    letter-spacing: 1px;
    font-weight: 600;
}
.change-avatar2 img {
    border-radius: 7px;
    height: 177px;
    width: 177px;
    object-fit: cover;
    margin-bottom: 17px;
    object-position: center top;
}
/* ------------------------------------------------------------------------- */
.disablsss{
  -webkit-filter: blur(1px);
  opacity:0.2;
  cursor: not-allowed;
}
.disablsss input{
  cursor: not-allowed;
}
.linksss .menu-item {
  list-style: none;
  display: inline-block;
  float: left;
}
.elementor2 h3{
  color: white;
  font-size: 25px;
  color: white;
  text-align: center;
  letter-spacing: 1px;
  margin-bottom: 17px;
  font-weight: 600;
}
.bbb.theme-btn2.theme-btn {
  margin-right: 0;
  padding: 17px;
}
.linksprosss{
  margin-top: 47px;
}
.linksprosss li {
  list-style: none;
  padding: 0px;
  margin: 0px;
  border-bottom: 1px solid lavender;
  margin-bottom: 10px;
  padding-bottom: 10px;
  font-size: 17px;
  letter-spacing: 1px;
  line-height: 37px;
  color: #444;
  font-family: "Lato",sans-serif;
}
/* ------------------------------------------------------------------------- */
.pagination {
  margin-top: 25px;
}
.pagination span {
cursor: pointer;
color: black;
float: left;
padding: 8px 16px;
text-decoration: none;
transition: background-color .3s;
border: 1px solid #ddd;
}

.pagination span.active {
  background-color: #632edd;
  color: white;
  border: 1px solid #0c3ca9;
}
/* ------------------------------------------------------------------------- */
.ccc {
  padding: 90px 0 77px;
  background-repeat: repeat;
  background-position: 50%;
  background-size: cover;
  z-index: -7;
  background-attachment: fixed;
  background-image: url(./Img/delete/slide-1.jpg);
}
.ccc .news-block-one .lower-content {
  position: relative;
  padding: 0px 30px 37px 30px;
}
.ccc .news-block-one .author-image {
  top: -37px;
  right: 7px;
}
.ccc2{
    position: absolute;
    top: -86px;
    right: 0;
}
.linksprosss2 {
  display: block;
  width: 100%;
  float: left;
  margin: 37px 0 0 0;
  padding: 7px 17px 7px 17px;
  background-color: #eef2fe;
  border-radius: 10px;
  box-shadow: 3px 3px 3px #dedada;
}
.linksprosss2 li {
  list-style: none;
  padding: 0px;
  margin: 0px;
  display: inline-block;
  margin: 0px 10px 0px 10px;
  cursor: pointer;
}
.linksprosss2 .chooseus3 {
  overflow: visible;
  color: #bcc3ce;
  margin-right: 17px;
  height: 37px;
  width: 37px!important;
  vertical-align: -7px;
  box-shadow: -2px -1px 3px #cad2dd;
}
.linksprosss2 .chooseus3:hover {
  color: #ff5700;
}
/* ------------------------------------------------------------------------- */
.searchbar {
  margin: 17px auto 47px;
  position: relative;
  display: table;
}
 .searchbar input {
  font-size: 18px;
  outline: none;
  -webkit-transition: all .2s;
  transition: all .2s;
  -webkit-transition-delay: .1s;
  transition-delay: .1s;
  width: 700px;
  height: 5rem;
  border: none;
  border-radius: 10rem;
  box-shadow: 0 1rem 2rem rgba(0,0,0,.1);
  padding: 2rem;
  font-family: Roboto,sans-serif;
} 
.searchbar .btnmmm {
  height: 2.7rem;
  position: absolute;
  top: 7px;
  right: 17px;
  border: 0;
  padding: 17px;
  background-color: rgba(246,125,65,0);
  color: #f67d41;
}
.searchbar .btnmmm img {
  height: 20px;
  top: 1.5rem;
  right: 1rem;
  -webkit-transition: all .2s;
  transition: all .2s;
  -webkit-transition-delay: .1s;
  transition-delay: .1s;
}
/* ------------------------------------------------------------------------- */
.successs2{
  padding: 90px 0 77px 0;
  background-repeat: repeat;
  background-position: center;
  background-size: cover;
  z-index: -7;
  background-attachment: fixed;
  background-image: url(./Img/delete/slide-1.jpg);
  min-height: 100vh;
}
.successs2 .nav-tabssss {
  position: absolute;
  top: -77px;
}
.city-card .card-info p span {
  color: #fff!important;
  font-size: 18px;
  line-height: 33px;
  background-color: transparent;
}
/* ------------------------------------------------------------------------- */
.fun-facts{
  display: none;
}
#services{
  display: none;
}
.mnsqqq{
  height: 157px;
  overflow: hidden;
}
.card-info .btn-box {
  z-index: 100000000000000;
}
.city-card:hover .utility-info{
  z-index: 0;
}
.renewtext{
  font-size: 18px;
  margin-bottom: 47px;
}
.renewtext2 {
  font-size: 17px;
  color: #676565;
  margin-top: 17px;
}
/* ------------------------------------------------------------------------- */
@media(max-width:767px){


  .banner .banners-image{ display: none;}
  .bannsss span { font-size: 18px;text-align: center;}
  .bannsss h1 {line-height: 47px;font-size: 30px;text-align: center;}
  .banner p {text-align: center;}


}
@media(max-width:992px){
  .menuq2 { background-color: white;  padding: 27px;  }
    .menu-item {  margin-bottom: 17px;  }
    .sub-menu{ margin-top: 17px; background-color: #f7f7f7; padding: 17px; }
    .header-middle img {
      margin: 0 auto;
      display: block;
  }
  .res5c {
    background: linear-gradient(90deg, var(--c1, #842af1), var(--c2, #033da4 ) 50%, var(--c1, #842af1)) var(--x, 0)/ 200%;
}
.res5c .svg-inline--fa {
  width: 30px;
  height: 30px;
  vertical-align: middle;
  padding: 3px;
}
.bannsss { margin-top: 27%;}
.banner { height: auto; }
.theme-btn {margin-right: 7px;}
.add-block .inner-box .image-column .inner-column { margin-right: 0; }
.add-block .inner-box .content-column {transform: translateY(0%);margin-bottom: 0px;}
.add-block .inner-box .content-column .inner-column {padding: 20px 17px;margin-left: 0;}
.aaa .news-block-one .author-image {display: none;}
.footer .content-box {margin-top: 27px;}
.aaa .link-box3 { top: -10px;}
.pricing-section.style-two {padding: 67px 0 0px 0px;}
.breadcrumbs { padding: 147px 0 57px 0; background-repeat: no-repeat;background-size: cover;}
.news-block-one .admin {display: none;}
.ccc .news-block-one .lower-content {padding: 0px 17px 35px 17px;}
.logsss3 { padding: 17px;}
.login-1 {padding: 47px 0 60px;}
.payment-titleqqq {margin-top: 37px;}
.aaa5 { padding: 17px;}
.aaa5 h4 {margin-bottom: 17px; }
.select-logo { display: none;}
.pymt-type-desc {width: 100%!important;}

}


@media (min-width:768px) and (max-width:992px){

  .news-block-one .post-info {margin-bottom: 7px; height: 43px;}
  .bbb.theme-btn {display: block;}
  .change-avatar2 img {height: 117px;width: 117px;}
  .elementor2 h3 {font-size: 20px;}
  .news-block-one .inner-box .image-box img {height: 177px;}
  .news-block-one .inner-box .image-box {min-height: 177px;}
  .gold-sectionview h2 {font-size: 20px;line-height: 33px;font-weight: 600;}
nav{float: right;}


}



@media (min-width:992px) and (max-width:1299px){

  ul.main-menu>li.menu-item>a {
    margin-left: 17px;
}

}
@media (min-width:992px) and (max-width:1200px){
  ul.main-menu>li.menu-item>a {margin-left: 10px!important;}
  .banner .theme-btn { margin-right: 10px; margin-top: 2px;padding: 14px 17px;}
  .pricing-block-two.active_block .pricing-table .choice-box {left: -26px;top: 17px;}
  .pricing-block-two .pricing-table .choice-box { padding: 3px 14px;}
  ul.main-menu>li.menu-item>a {font-size: 16px;}
  .elementor h3 {font-size: 20px;}
  .elementor h4 {font-size: 20px;}
  .linksss .menu-item { margin-bottom: 0px;}
  

}
/* ------------------------------------------------------------------------- */
